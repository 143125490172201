import {register, SwiperContainer} from 'swiper/element/bundle';
import {contentSliderStyles} from "@/components/content-slider/content-slider.styles.ts";
import {
    sbkCoreGridLgGutter,
    sbkCoreGridMdGutter,
    sbkCoreGridSmGutter, sbkCoreGridXlGutter
} from "@/tokens/variables";

const SbkContentSlider = {
    initialize() {
        register();
        const swiperElements: NodeListOf<SwiperContainer> = document.querySelectorAll('[variant="content"]');
        swiperElements.forEach((swiperElement) => {
            const params = {
                a11y: true,
                navigation: true,
                watchOverflow: true,
                pagination: {
                    type: 'custom',
                    renderCustom: function (_swiper: SwiperContainer, current: string, total: string) {
                        if (Number(current) < 10) {
                            current = '0' + current;
                        }
                        if (Number(total) < 10) {
                            total = '0' + total;
                        }
                        return '<span><b>' + current + '</b></span><span>' + '/' + '</span><span>' + total + '</span>';
                    }
                },
                injectStyles: [
                    contentSliderStyles,
                ],
                slidesPerView: parseInt(swiperElement.dataset.mobile ?? '1') + 0.2,
                spaceBetween: sbkCoreGridSmGutter,
                breakpoints: {
                768: {
                    slidesPerView: parseInt(swiperElement.dataset.tablet  ?? '1') + 0.2,
                    spaceBetween: sbkCoreGridMdGutter,
                },
                992: {
                    slidesPerView: parseInt(swiperElement.dataset.desktop  ?? '1'),
                    spaceBetween: sbkCoreGridLgGutter,
                },
                    1280: {
                        slidesPerView: parseInt(swiperElement.dataset.desktop  ?? '1'),
                        spaceBetween: sbkCoreGridXlGutter,
                    },
            },
            };
            Object.assign(swiperElement, params);
            swiperElement.initialize();
        });
    }
}

export default SbkContentSlider